import { Add, Lock } from "@mui/icons-material";
import CourseBlock from "./CourseBlock/CourseBlock";
import useStore, { hoursForTerm, term } from "../../../store";
import "./Term.css";
import { useState } from "react";
import Wizard from "../../Wizard/Wizard";

interface TermProps {
  termNumber: number;
  locked: boolean;
  name: string;
}

export default function Term({ termNumber, name, locked }: TermProps) {
  const courseList = useStore(term(termNumber));
  const credits = useStore(hoursForTerm(termNumber));

  const [wizard, setWizard] = useState(false);
  const [placeholder, setPlaceholder] = useState('');

  const editPlaceholder = (req: string): void => {
    setPlaceholder(req);
    setWizard(true);
  }

  return (
    <div className="term-wrapper">
      <div className="term-header">
        {locked && <Lock />}
        <h3 className="term-title">{name}</h3>
        <div className="term-credit-hours">{credits} hours</div>
      </div>
      <div className="term-body">
        {courseList.map((entry, i) => (
          <CourseBlock
            key={i}
            entry={entry}
            locked={locked}
            termNumber={termNumber}
            editPlaceholder={editPlaceholder}
          />
        ))}
      </div>
      {!locked && (
        <div className="term-footer">
          <button className="term-add-button" onClick={() => setWizard(true)}>
            <Add />
            Add Course or Requirement
          </button>
        </div>
      )}

      {wizard && <Wizard onClose={() => {setWizard(false); setPlaceholder('')}} term={termNumber} placeholder={placeholder} />}
    </div>
  );
}
