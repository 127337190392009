import { useState, useEffect } from "react";
import { Add, Check } from "@mui/icons-material";
import { Course } from "../../../data/course.model";
import useStore, { termOfCourse } from "../../../store";
import "./SearchResult.css";

interface SearchResultProps {
  course: Course;
  term: number;
  setTerm: (newValue: number) => void;
  onSubmit: () => void;
}

export default function SearchResult(props: SearchResultProps) {
  const { course, term, setTerm, onSubmit } = props;
  const [active, setActive] = useState(false);
  const courseTerm = useStore(termOfCourse(course.courseNumber));
  const addCourse = useStore((store) => store.addCourseToTerm);

  const listOfTerms = [
    "Fall 2020",
    "Spring 2021",
    "Fall 2021",
    "Spring 2022",
    "Fall 2022",
    "Spring 2023",
    "Fall 2023",
    "Spring 2024",
  ];

  useEffect(() => setActive(false), [course]);

  return (
    <li className="search-result" key={course.courseNumber}>
      {active ? (
        <div className="search-result-confirmation">
          <p>Which term would you like to add "{course.courseTitle}" to?</p>
          <div className="cta-container">
            <select
              value={term.toString()}
              onChange={(event) => setTerm(parseInt(event.target.value, 10))}
            >
              {listOfTerms.slice(3).map((term, index) => (
                <option value={index + 3}>{term}</option>
              ))}
            </select>
            <button onClick={() => setActive(false)} className="cta-buttons">
              Cancel
            </button>
            <button
              className="cta-buttons"
              onClick={() => {
                addCourse(term, course.courseNumber);
                onSubmit();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div
            className={`search-result-listing ${
              courseTerm === null
                ? "remaining"
                : courseTerm < 3
                ? "completed"
                : "scheduled"
            }`}
            onClick={() => {
              courseTerm === null && setActive(true);
            }}
          >
            {courseTerm === null && (
              <Add sx={{ fontSize: 20 }} className="search-result-add" />
            )}
            <div className="search-result_course-number">
              {course.courseNumber}
            </div>
            <div className="search-result_course-title">{course.courseTitle}</div>
            {courseTerm !== null ? (
              <p className="search-result-course-term">
                {listOfTerms[courseTerm]}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="course-req-fulfilled">
              <Check style={{ height: 16 }} />
              <p className="meets-requirement">
                Meets {course.requirement} Requirement
              </p>
            </div>
        </div>
      )}
    </li>
  );
}
