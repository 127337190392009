import { Check, Edit, HighlightOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import courses from "../../../../data/Course";
import useStore from "../../../../store";
import hourFormat from "../../../../utils/hourFormat";
import "./CourseBlock.css";

interface CourseBlockProps {
  entry: [type: "placeholder" | "course", code: string];
  locked: boolean;
  termNumber: number;
  editPlaceholder: (req: string) => void;
}

export default function CourseBlock({
  entry,
  locked,
  termNumber,
  editPlaceholder,
}: CourseBlockProps) {
  const remove = useStore((state) => state.removeFromTerm);

  const [type, code] = entry;

  if (type === "placeholder") {
    return (
      <div className="course-block requirement-placeholder">
        {!locked && (
          <IconButton
            style={{ padding: 0 }}
            onClick={() => remove(termNumber, code)}
          >
            <HighlightOff />
          </IconButton>
        )}
        <Edit
          style={{ cursor: "pointer" }}
          onClick={() => editPlaceholder(code)}
        />
        <span className="course-title">{code}</span>
      </div>
    );
  }

  const course = courses.getCourse(code)!;

  return (
    <div className="course-block filled">
      {!locked && (
        <IconButton
          style={{ padding: 0 }}
          onClick={() => remove(termNumber, code)}
        >
          <HighlightOff />
        </IconButton>
      )}
      <div className={`course-block-inner${locked ? "" : " unlocked"}`}>
        <div className="course-details">
          <span
            className={`course-number ${
              termNumber >= 3 ? "scheduled" : "completed"
            }`}
          >
            {course.courseNumber}
          </span>
          <span className="course-title">{course.courseTitle}</span>
          <span className="credit-hours">{hourFormat(course.creditHours)}</span>
        </div>
        <div className="course-requirement-fulfilled">
          <Check style={{ height: 16 }} />
          <p className="requirement-description">
            Meets {course.requirement} Requirement
          </p>
        </div>
      </div>
    </div>
  );
}
