import Requirement from "../Requirement/Requirement";
import {
  CalendarToday,
  CheckCircleOutline,
  ErrorOutline,
} from "@mui/icons-material";
import "./RequirementsList.css";
import useStore, { fulfilledCourses } from "../../../store";

const RemainingHeader = () => (
  <div className="requirements-list-header remaining">
    <ErrorOutline />
    <h2>Remaining Requirements</h2>
  </div>
);

const ScheduledHeader = () => (
  <div className="requirements-list-header scheduled">
    <CalendarToday />
    <h2>Scheduled Requirements</h2>
  </div>
);

const CompletedHeader = () => (
  <div className="requirements-list-header completed">
    <CheckCircleOutline />
    <h2>Completed Requirements</h2>
  </div>
);

interface RequirementsListProps {
  type: "remaining" | "scheduled" | "completed";
  requirements: string[];
}

export default function RequirementsList(props: RequirementsListProps) {
  const { type, requirements } = props;
  const courses = useStore(fulfilledCourses);

  return (
    <div className="requirements-list">
      {type === "remaining" ? (
        <RemainingHeader />
      ) : type === "scheduled" ? (
        <ScheduledHeader />
      ) : (
        <CompletedHeader />
      )}
      <div className="requirements-list-body">
        {requirements.map((r) => {
          const course = courses.find((c) => c.requirement === r);

          return <Requirement key={r} course={course} requirement={r} />;
        })}
      </div>
    </div>
  );
}
