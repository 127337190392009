import { Add, Close } from "@mui/icons-material";

import "./SelectReq.css";
import useStore from "../../../store";
import { requirements } from "../../../data/requirements";
import courses from "../../../data/courses.json";

export default function SelectReq({
  term,
  onClose,
}: {
  term: number;
  onClose: () => void;
}) {
  const addPlaceholderToTerm = useStore((store) => store.addPlaceholderToTerm);
  const addReq = (req: string) => {
    addPlaceholderToTerm(term, req);
    onClose();
  };

  const courseList: any = courses;
  console.log(courseList);
  let remainingReqs = requirements;
  // [req, credits]
  let reqs: [string, string][] = [];
  const terms = useStore((store) => store.terms);
  if (terms) {
    // Get requirements that are met
    const scheduledRequirements = terms
      .flat(1)
      .filter((course) => course[0] === "course")
      .map((course) => courseList[course[1]].requirement);

    // Get unmet requirements
    reqs = remainingReqs
      .filter((req) => !scheduledRequirements.includes(req))
      .map((req) => {
        const credits = Object.keys(courseList)
          .filter((course: string) => courseList[course].requirement === req)
          .map((course: string) => courseList[course].creditHours)
          .reduce((a: string, b: string) => (a !== "" ? a : b), "");
        return [req, credits];
      });
  }

  return (
    <div>
      <div className="pageOverlay" onClick={onClose}></div>
      <div className="selectBox">
        <Close sx={{fontSize: 30}} className="close-button" onClick={onClose} />
        <h2 className="wizardH2">Add a Requirement PlaceHolder</h2>
        <p className="wizardDesc">
          You will have to go back later to add course to fulfill this
          requirement
        </p>
        <h3 className="wizardH3">Remaining Requirements</h3>
        <div className="reqList">
          {reqs.map((req) => (
            <button
              key={req[0]}
              className="addReqBtn"
              onClick={(e) => {
                addReq(req[0]);
              }}
            >
              <span className="middle">
                <Add
                  sx={{
                    fontSize: 16,
                    justifySelf: "left",
                    marginRight: "10px",
                  }}
                />
                {req[0]}
              </span>
              <span className="right">{req[1] === "" ? "3hr" : req[1]}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
