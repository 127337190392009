import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./theme.css";
import App from "./App";
import useStore from "./store";

useStore.getState().addCourseToTerm(0, "CIVE 112");
useStore.getState().addCourseToTerm(0, "CSCE 101");
useStore.getState().addCourseToTerm(0, "ENGR 10");
useStore.getState().addCourseToTerm(0, "CHEM 109A");
useStore.getState().addCourseToTerm(0, "CHEM 109L");
useStore.getState().addCourseToTerm(0, "MATH 106");
useStore.getState().addCourseToTerm(0, "HIST 210");
useStore.getState().addCourseToTerm(1, "CIVE 131");
useStore.getState().addCourseToTerm(1, "MATH 107");
useStore.getState().addCourseToTerm(1, "PHYS 211");
useStore.getState().addCourseToTerm(1, "JOMC 317");
useStore.getState().addCourseToTerm(2, "ENGR 20");
useStore.getState().addCourseToTerm(2, "JGEN 200");
useStore.getState().addCourseToTerm(2, "MATH 208");
useStore.getState().addCourseToTerm(2, "MECH 223");
useStore.getState().addCourseToTerm(2, "CHEM 110A");
useStore.getState().addCourseToTerm(2, "CHEM 110L");
useStore.getState().addCourseToTerm(2, "ARTP 270");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
