export const requirements = [
  "CIVE Engr (Term 1)",
  "Computer Skills",
  "Freshman Seminar (Term 1)",
  "ACE 4 General Chemistry",
  "ACE 3 Calculus I",
  "ACE 5 Humanities",
  "CIVE Engr (Term 2)",
  "ACE 3 Calculus II",
  "ACE 4 General Physics",
  "ACE 6 Social Sciences",
  "Soph Seminar (Term 3)",
  "ACE 1 Written Comm",
  "ACE 3 Calculus III",
  "ENGR Mechanics (Term 3)",
  "Chemistry Physics Option",
  "ACE 7 Arts",
  "CIVE Engr (Term 4)",
  "ACE 2 Oral Comm",
  "Differential Equations",
  "ENGR Mechanics (Term 4)",
  "CIVE Engr (Term 5)",
  "Statistical Applications",
  "CIVE Engr (Term 6)",
  "ACE 8 Ethical Principles",
  "CIVE Science Elective",
  "ACE 9 Global/Human Divers",
  "Calculus II Supplement",
  "ACE 10 Capstone",
];
