import SearchBar from "../SearchBar/SearchBar";
import "./Header.css";

export default function Header() {
  return (
    <div className="header">
      <div className="header-start">
        <h1 className="header-title">Civil Engineering Major (Fall 2020)</h1>
      </div>

      <div className="header-end">
        <SearchBar />
      </div>
    </div>
  );
}
