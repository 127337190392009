import { useEffect, useState } from "react";
import { Search, FilterList, Add, Check, Clear, Close } from "@mui/icons-material";
import Dropdown from "react-bootstrap/Dropdown";

import useStore from "../../../store";
import "./SelectCourse.css";
import { Course } from "../../../data/course.model";
import coursesData from "../../../data/Course";
import { requirements } from "../../../data/requirements";

export default function SelectCourse({
  term,
  onClose,
  placeholder,
}: {
  term: number;
  onClose: () => void;
  placeholder: string;
}) {
  const initCourses: Course[] = [];
  const [{ searchText, filter, courses }, setState] = useState({
    searchText: "",
    filter: "",
    courses: initCourses,
  });

  function updateFilter(newFilter: string) {
    let results: Course[] = allCourses();
    if (searchText !== "") {
      results = coursesData.search(searchText);
    }
    if (newFilter !== "") {
      results = applyFilter(results, newFilter);
    }
    setState({
      filter: newFilter,
      courses: results,
      searchText,
    });
  }

  useEffect(() => {
    setState({
      searchText,
      courses,
      filter: placeholder,
    });
    updateFilter(placeholder);
    // eslint-disable-next-line
  }, [placeholder]);

  const addCourseToTerm = useStore((store) => store.addCourseToTerm);
  const removeFromTerm = useStore((store) => store.removeFromTerm);
  const currTermCourses = useStore((store) => store.terms[term]);

  const applyFilter = (courses: Course[], filter: string) => {
    return courses.filter((course) => filter === course.requirement);
  };

  const allCourses = (): Course[] => {
    let results: Course[] = coursesData.all;
    const currCourses = currTermCourses
      .filter((c) => c[0] === "course")
      .map((c) => c[1]);
    results = results.filter(
      (course) => !currCourses.includes(course.courseNumber)
    );
    return results;
  };

  const updateSearch = (event: any) => {
    const query = event.target.value;
    let results: Course[] = allCourses();
    if (query !== "") {
      results = coursesData.search(query);
    }
    if (filter !== "") {
      results = applyFilter(results, filter);
    }
    setState({
      searchText: event.target.value,
      courses: results,
      filter,
    });
  };

  const addCourse = (course: string) => {
    addCourseToTerm(term, course);
    onClose();
    if (placeholder) {
      removeFromTerm(term, placeholder);
    }
  };

  return (
    <div>
      <div className="pageOverlay" onClick={onClose}></div>
      <div className="selectBox">
        <Close sx={{fontSize: 30}} className="close-button" onClick={onClose} />
        <h2 className="wizardH2">Add a Course</h2>
        <Search
          sx={{
            fontSize: 18,
            position: "absolute",
            marginLeft: "10px",
            marginTop: "10px",
          }}
          className="wizardIcon"
        />
        <input
          type="text"
          className="searchQuery"
          placeholder="Search for courses..."
          value={searchText}
          onChange={updateSearch}
        ></input>
        {placeholder === "" ? (
          <div>
            <Dropdown>
              <Dropdown.Toggle className="filter">
                <Clear
                  sx={{
                    fontSize: 16,
                    position: "absolute",
                    marginLeft: "318px",
                    marginTop: "5px",
                  }}
                  onClick={(e) => updateFilter("")}
                />
                <FilterList sx={{ fontSize: 16 }} />
                {filter === "" ? <span>Filter Requirements</span> : filter}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdownMenu">
                {requirements.map((req) => (
                  <Dropdown.Item
                    key={req}
                    onClick={(e) => {
                      updateFilter(req);
                    }}
                  >
                    {req}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null}
        {searchText.trim() === "" && filter === "" ? (
          <div className="searchSuggest">
            <div className="searchSuggestBox">
              <div className="wizardIconCtn">
                <Search sx={{ fontSize: 50 }} className="wizardIcon" />
              </div>
              <span className="searchText">
                Search for a specific course, or filter all courses by a
                requirement
              </span>
            </div>
          </div>
        ) : (
          <div className="reqList">
            {courses.map(
              ({ courseNumber, courseTitle, creditHours, requirement }) => (
                <button
                  className="courseBtn"
                  key={courseNumber}
                  onClick={(e) => addCourse(courseNumber!)}
                >
                  <Add
                    sx={{ fontSize: 20, display: "inline" }}
                    className="plusIcon"
                  />
                  <div className="courseInfo">
                    <div>
                      <span className="courseNum">{courseNumber}</span>
                      <span className="courseTitle">{courseTitle}</span>
                      <span className="credits">{creditHours}hr</span>
                    </div>
                    <div className="req">
                      <Check sx={{ fontSize: 16, display: "inline" }} />
                      Meets {requirement}
                    </div>
                  </div>
                </button>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}
