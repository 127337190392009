import { Course } from "./course.model";
import data from "./courses.json";

interface CourseDataEntry {
  courseNumber: string;
  courseTitle: string;
  creditHours: string;
  requirement: string;
}

type CourseData = Record<string, CourseDataEntry>;

class Courses {
  private courseMap: Map<string, Course>;

  constructor(data: CourseData) {
    this.courseMap = new Map<string, Course>();

    for (const courseNumber of Object.keys(data)) {
      const courseData = data[courseNumber];
      const course = {
        ...courseData,
        creditHours: parseInt(courseData.creditHours),
      } as Course;

      this.courseMap.set(courseNumber, course);
    }
  }

  getCourse(courseNumber: string): Course | undefined {
    return this.courseMap.get(courseNumber);
  }

  [Symbol.iterator]() {
    return this.courseMap.values();
  }

  get all() {
    return Array.from(this.courseMap.values());
  }

  search(term: string): Course[] {
    if (!term) return [];

    return this.all.filter((course) => {
      const query = term.toLocaleLowerCase();
      const corpus = [course.courseNumber, course.courseTitle]
        .map((f) => f?.toLocaleLowerCase())
        .join(" ");
      return corpus.includes(query);
    });
  }
}

const courses = new Courses(data);

export default courses;
