import { useState } from "react";
import { Search } from "@mui/icons-material";

import SearchResult from "./SearchResult/SearchResult";
import useStore from "../../store";
import { Course } from "../../data/course.model";
import courses from "../../data/Course";
import "./SearchBar.css";

export default function SearchBar() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<Course[]>([]);
  const [term, setTerm] = useState(3);
  const [open, setOpen] = useState(false);

  function handleSearch(value: string) {
    setOpen(true);
    setQuery(value);
    setResults(courses.search(value));
  }

  const terms = useStore((store) => store.terms);
  let complete: Course[] = [];
  let incomplete: Course[] = [];
  if (terms) {
    // Get requirements that are met
    const completedCourses = terms
      .flat(1)
      .filter(course => course[0] === "course")
      .map(course => course[1]);
    complete = results.filter(course => completedCourses.includes(course.courseNumber));
    incomplete = results.filter(course => !completedCourses.includes(course.courseNumber));
  }
  const sortedResults = complete.concat(incomplete);

  return (
    <div>
      {open && sortedResults.length > 0 && (
        <div className="search-page-overlay" onClick={() => {setOpen(false)}}></div>
      )}
      <div className="search-container">
        <div className="search-bar-container">
          <Search sx={{ fontSize: 20 }} className="search-icon" />
          <input
            placeholder="Type to search courses..."
            type="text"
            className="search-bar"
            value={query}
            onChange={(event) => handleSearch(event.target.value)}
            onFocus={(event) => setOpen(true)}
          />
        </div>
        {open && sortedResults.length > 0 && (
          <div className="search-results-container">
            <ul>
              {sortedResults.map((course) => (
                <SearchResult
                  course={course}
                  term={term}
                  setTerm={setTerm}
                  onSubmit={() => handleSearch("")}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
