import { Check, ErrorOutline } from "@mui/icons-material";
import { Course } from "../../../data/course.model";
import hourFormat from "../../../utils/hourFormat";
import "./Requirement.css";

interface RequirementProps {
  course?: Course;
  requirement: string;
}

export default function Requirement(props: RequirementProps) {
  const { course, requirement } = props;

  // const term = useStore(termOfCourse(course.courseNumber));

  return (
    <div className="requirement">
      <div className="title-hours-wrapper">
        <span>{requirement}</span>
        <span>{course ? hourFormat(course.creditHours) : ""}</span>
      </div>
      {course && (
        <div className="associated-course">
          {course.courseNumber === undefined ? (
            <>
              <ErrorOutline />
              <span>No class selected to fulfill requirement</span>
            </>
          ) : (
            <>
              <Check style={{ height: "0.8em" }} />
              <span>
                {course.courseTitle} ({course.courseNumber})
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
}
