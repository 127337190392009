import create from "zustand";
import produce from "immer";
import courses from "./data/Course";
import { requirements } from "./data/requirements";

interface Store {
  terms: [type: "course" | "placeholder", id: string][][];
  mappings: Map<string, string>;

  addCourseToTerm: (term: number, course: string) => void;
  addPlaceholderToTerm: (term: number, requirement: string) => void;
  removeFromTerm: (term: number, id: string) => void;
}

const useStore = create<Store>((set) => ({
  terms: [[], [], [], [], [], [], [], []],
  mappings: new Map(),

  addCourseToTerm: (term: number, course: string) => {
    set(
      produce((state) => {
        state.terms[term].push(["course", course]);
      })
    );
  },

  addPlaceholderToTerm: (term: number, requirement: string) => {
    set(
      produce((state) => {
        state.terms[term].push(["placeholder", requirement]);
      })
    );
  },

  removeFromTerm: (term: number, id: string) => {
    set(
      produce((state: Store) => {
        state.terms[term] = state.terms[term].filter((item) => item[1] !== id);
      })
    );
  },
}));

export default useStore;

export const terms = (state: Store) => state.terms;
export const term = (term: number) => (state: Store) => state.terms[term];
export const placeholders = (state: Store) =>
  state.terms.flatMap((term) =>
    term.filter((item) => item[0] === "placeholder")
  );
export const fulfilledCourses = (state: Store) =>
  state.terms
    .flatMap((term) => term.filter((item) => item[0] === "course"))
    .map((course) => courses.getCourse(course[1])!)
    .filter((e) => e);
// export const fulfilledRequirements = (state: Store) =>
export const fulfills = (requirement: string) => (state: Store) =>
  state.mappings.get(requirement);

export const hoursForTerm = (term: number) => (state: Store) =>
  state.terms[term].reduce(
    (acc, item) =>
      item[0] === "course"
        ? acc + courses.getCourse(item[1])!.creditHours
        : acc,
    0
  );

export const completedRequirements = (state: Store) =>
  requirements.filter((r) =>
    state.terms
      .slice(0, 3)
      .flat()
      .map((t) => courses.getCourse(t[1])!.requirement)
      .includes(r)
  );

export const scheduledRequirements = (state: Store) =>
  requirements.filter((r) =>
    state.terms
      .slice(3)
      .flat()
      .map((t) =>
        t[0] === "course" ? courses.getCourse(t[1])!.requirement : t[1]
      )
      .includes(r)
  );

export const remainingRequirements = (state: Store) => {
  const scheduled = scheduledRequirements(state);
  const completed = completedRequirements(state);
  return requirements.filter(
    (r) => !scheduled.includes(r) && !completed.includes(r)
  );
};

export const termOfCourse = (course: string) => (state: Store) => {
  const term = state.terms.findIndex((t) =>
    t.some((i) => i[0] === "course" && i[1] === course)
  );
  return term === -1 ? null : term;
};
