import { History, Assignment, Close } from '@mui/icons-material';

import "./Intro.css";

export default function Intro(props: {setScreen: (screen: string) => void, onClose: () => void;}) {

  const selectCourse = () => {
    props.setScreen('selectCourse');
  }

  const selectReq = () => {
    props.setScreen('selectReq');
  }

  return (
    <div>
      <div className="pageOverlay" onClick={props.onClose}></div>
      <div className="wizard">
        <Close sx={{fontSize: 30}} className="close-button" onClick={props.onClose} />
        <div className="wizardSection">
          <button className="wizardBtn" onClick={selectCourse} >
            <div className="wizardIconCtn">
              <Assignment sx={{fontSize: 50}} className="wizardIcon" />
            </div>
            <span className="wizardText" >Add a specific course</span>
          </button>
        </div>
        <div className="divider"></div>
        <div className="wizardSection"> 
          <button className="wizardBtn" onClick={selectReq} >
            <div className="wizardIconCtn">
              <History sx={{ fontSize: 50 }} className="wizardIcon" />
            </div>
            <span className="wizardText" >Add a placeholder for a requirement to fill in later</span>
          </button>
        </div>
      </div>
    </div>
  )
}