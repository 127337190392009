import { useEffect, useState } from "react";

import Intro from "./Intro/Intro";
import SelectReq from "./SelectReq/SelectReq";
import SelectCourse from "./SelectCourse/SelectCourse";
import "./Wizard.css";

export default function Wizard({
  term,
  onClose,
  placeholder = ''
}: {
  term: number;
  onClose: () => void;
  placeholder?: string;
}) {
  const [screen, setScreen] = useState(placeholder ? 'selectCourse' : 'intro');

  useEffect(() => {
    document.body.style.setProperty('overflow', 'hidden');

    return () => {
      document.body.style.setProperty('overflow', 'initial');
    }
  }, []);

  const Screen = () => {
    switch (screen) {
      case "selectCourse":
        return <SelectCourse term={term} onClose={onClose} placeholder={placeholder}/>;
      case "selectReq":
        return <SelectReq term={term} onClose={onClose} />;
      case "intro":
      default:
        return <Intro setScreen={setScreen} onClose={onClose} />;
    }
  }

  return <div style={{ display: 'block', position: 'fixed', top: 0, height: '100vh', width: '100vw', left: 0 }}>
      <Screen />
  </div>
}
