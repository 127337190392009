import Term from "./Term/Term";
import "./TermsSection.css";

const termList = [
  { locked: true, name: "Fall 2020" },
  { locked: true, name: "Spring 2021" },
  { locked: true, name: "Fall 2021" },
  { locked: false, name: "Spring 2022" },
  { locked: false, name: "Fall 2022" },
  { locked: false, name: "Spring 2023" },
  { locked: false, name: "Fall 2023" },
  { locked: false, name: "Spring 2024" },
];

export default function TermsSection() {
  return (
    <div className="terms-wrapper">
      {termList.map((term, index) => (
        <Term
          key={index}
          termNumber={index}
          locked={term.locked}
          name={term.name}
        />
      ))}
    </div>
  );
}
