import useStore, {
  completedRequirements,
  remainingRequirements,
  scheduledRequirements,
} from "../../store";
import RequirementsList from "./RequirementsList/RequirementsList";
import "./RequirementsSection.css";

export default function RequirementsSection() {
  const completed = useStore(completedRequirements);
  const scheduled = useStore(scheduledRequirements);
  const remaining = useStore(remainingRequirements);

  return (
    <div className="requirements-wrapper">
      <RequirementsList type="remaining" requirements={remaining} />
      <RequirementsList type="scheduled" requirements={scheduled} />
      <RequirementsList type="completed" requirements={completed} />
    </div>
  );
}
