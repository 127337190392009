import Header from "../Header/Header";
import RequirementsSection from "../RequirementsSection/RequirementsSection";
import TermsSection from "../TermsSection/TermsSection";
import "./Layout.css";

export function Layout() {
  return (
    <div className="layout-wrapper">
      <Header />
      <RequirementsSection />
      <TermsSection />
    </div>
  );
}
